/* eslint no-console:0 */
import {
  GOVUKInitAll,
  HMRCInitAll,
  ClearCookies,
} from "dvla-external-frontend/src/components/index";
import formUtils from "./js-form-utils";

require.context('./stylesheets/', true, /^\.\/[^_].*\.(css|scss|sass)$/i);
// ensure images are included in the compiled pack
require.context('./images', true);
// ensure dvla-external-frontend/dist/media are included in the compiled pack for favicon, fonts etc
require.context('dvla-external-frontend/dist/media', true);

GOVUKInitAll();
HMRCInitAll();
ClearCookies.init();

window.onload = () => {
  formUtils.registerInputListeners();
};
