function forceInputNumeric(e) {
  const start = e.target.selectionStart;
  const end = e.target.selectionEnd;
  e.target.value = e.target.value.replace(/[^\d\s]/g, '');
  e.target.setSelectionRange(start, end);
}

function forceInputAlphnumeric(e) {
  const start = e.target.selectionStart;
  const end = e.target.selectionEnd;
  e.target.value = e.target.value.replace(/[^a-zA-Z\d\s]/g, '');
  e.target.setSelectionRange(start, end);
}

function forceInputUppercase(e) {
  const start = e.target.selectionStart;
  const end = e.target.selectionEnd;
  e.target.value = e.target.value.toLocaleUpperCase();
  e.target.setSelectionRange(start, end);
}

function forceInputNoSpaces(e) {
  const start = e.target.selectionStart;
  const end = e.target.selectionEnd;
  e.target.value = e.target.value.replace(/\s/g, '');
  e.target.setSelectionRange(start, end);
}

export default {
  registerInputListeners: () => {
    const uppercaseInputs = document.getElementsByClassName('uppercase');
    if (uppercaseInputs) {
      for (let i = 0; i < uppercaseInputs.length; i += 1) {
        uppercaseInputs[i].addEventListener('input', forceInputUppercase);
      }
    }

    const alphanumericInputs =
      document.getElementsByClassName('alphanumericOnly');
    if (alphanumericInputs) {
      for (let i = 0; i < alphanumericInputs.length; i += 1) {
        alphanumericInputs[i].addEventListener('input', forceInputAlphnumeric);
      }
    }

    const numericInputs = document.getElementsByClassName('numericOnly');
    if (numericInputs) {
      for (let i = 0; i < numericInputs.length; i += 1) {
        numericInputs[i].addEventListener('input', forceInputNumeric);
      }
    }

    const noPasteElements = document.getElementsByClassName('noPaste');
    if (noPasteElements) {
      for (let i = 0; i < noPasteElements.length; i += 1) {
        noPasteElements[i].addEventListener('paste', (e) => {
          e.preventDefault();
        });
      }
    }

    const noSpaceElements = document.getElementsByClassName('noSpace');
    if (noSpaceElements) {
      for (let i = 0; i < noSpaceElements.length; i += 1) {
        noSpaceElements[i].addEventListener('input', forceInputNoSpaces);
      }
    }
  },
};
